import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

// core components
import Card from "src/components/Card/Card.js"
import CardBody from "src/components/Card/CardBody.js"
import CardHeader from "src/components/Card/CardHeader.js"
import GridContainer from "src/components/Grid/GridContainer.js"
import GridItem from "src/components/Grid/GridItem.js"

import { Typography, List, ListItem } from "@material-ui/core"

import styles from "src/assets/jss/material-kit-react/views/aboutPage/sections/aboutSectionStyles"
import cardStyles from "src/assets/jss/material-kit-react/components/cardStyle"
const useStyles = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles)

const AboutSection = ({ pageContent, globalState }) => {
  const classes = useStyles()
  const cardClasses = useCardStyles()
  const {
    headerText_page,
    text_aboutBusinessSubheader,
    text_aboutBusiness,
    text_aboutTheOwnerSubheader,
    refStaffBio_owner,
    text_brokerName,
    text_brokerLicenseNumber,
    text_ownersLicenseName,
    text_ownersLicenseNumber,
    text_sectionHeaderCertificates,
  } = pageContent

  const {
    text_fullBiography,
    salutation,
    text_lastName,
    text_firstName,
    refCertificates,
    media_headshot,
  } = refStaffBio_owner

  /**
  const getFullName = () => {
    //let full = salutation + " " hide for now
    let full = text_firstName + " "
    full += text_lastName
    return full
  }

   */
  return (
    <Card className={classNames(classes.aboutSection)}>
      <CardHeader color="primary">{headerText_page}</CardHeader>

      <CardBody
        className={classNames(
          "cardBody",
          classes.cardBody,
          cardClasses.cardBody
        )}
      >
        <GridContainer className="padded" direction="column">
          <GridItem className="about-description" xs={12} sm={12} md={8}>
            <Typography
              variant="h4"
              className="pageSubheader-root"
              gutterBottom
            >
              {text_aboutBusinessSubheader}
            </Typography>
            <div
              className="MuiTypography-body1 MuiTypography-gutterBottom"
              dangerouslySetInnerHTML={{
                __html: text_aboutBusiness.childMarkdownRemark.html,
              }}
            />
            <Typography variant="body1" gutterBottom>
              {text_ownersLicenseName +
                " | BRE License #" +
                text_ownersLicenseNumber}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {text_brokerName + " | BRE License #" + text_brokerLicenseNumber}
            </Typography>
          </GridItem>

          <GridItem
            className={"about-description with-photo"}
            xs={12}
            sm={12}
            md={8}
          >
            <div className="name-bio-photo-row">
              <div className="photo">
                <img
                  src={media_headshot.file.url}
                  alt={"Photo of Kiwi"}
                  className={classes.imgRoundedCircle + " " + classes.imgFluid}
                />
              </div>
            </div>
            <Typography
              variant="h4"
              className="pageSubheader-root"
              gutterBottom
            >
              {text_aboutTheOwnerSubheader}
            </Typography>
            <div
              className="MuiTypography-body1 MuiTypography-gutterBottom"
              dangerouslySetInnerHTML={{
                __html: text_fullBiography.childMarkdownRemark.html,
              }}
            />

            {refCertificates && refCertificates.length > 0 && (
              <>
                <Typography
                  variant="h4"
                  className="pageSubheader-root"
                  gutterBottom
                >
                  {text_sectionHeaderCertificates}
                </Typography>
                <List>
                  {refCertificates.map((c, i) => {
                    return <ListItem key={i}>{c.text_certificateName}</ListItem>
                  })}
                </List>
              </>
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default AboutSection

/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SiteVariablesFragment from "src/components/QueryFragments"
import { AboutPageFragment } from "src/components/QueryFragments"

import "src/assets/scss/material-kit-react.scss"

import AboutPageView from "../views/AboutPage"

const AboutPage = ({ location }) => {
  const {
    contentfulWebsiteGlobalVariables,
    contentfulPageAbout
  } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteGlobalVariables {
          ...SiteVariablesFragment
        }
        contentfulPageAbout {
          ...AboutPageFragment
        }
      }
    `
  )

  return (
    <AboutPageView
      siteVariables={contentfulWebsiteGlobalVariables}
      pageContent={contentfulPageAbout}
      location={location}
    />
  )
}

export default AboutPage

import {
  primaryTextColor,
  defaultMobileFontSize,
  defaultDesktopFontSize,
  container,
  getCalcRem,
} from "src/assets/jss/material-kit-react.js"
import defaultComponentStyles from "src/assets/jss/material-kit-react/defaultComponentStyles"

const aboutSectionStyles = theme => ({
  ...defaultComponentStyles,
  aboutSection: {
    ...container,
    "& .cardBody": {
      "& .about-description": {
        display: "flex",
        flexDirection: "column",
        "& ul": {
          flexWrap: "unset",
          width: "auto",
          flexDirection: "column",
          margin: "0 auto",
          justifySelf: "center",
          paddingLeft: "0",
        },
      },
      "& .name-bio-photo-row": {
        display: "flex",
        [theme.breakpoints.up("sm")]: {
          width: "50%",
          margin: "0 auto",
        },
        "& .photo": {
          justifySelf: "center",
          margin: getCalcRem(16) + " auto",
          [theme.breakpoints.up("md")]: {
            margin: getCalcRem(32) + " auto",
          },
        },
      },
    },
  },
})

export default aboutSectionStyles

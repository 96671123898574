import defaultPageStyles from "src/assets/jss/material-kit-react/defaultPageStyles"

const aboutPageStyles = theme => ({
  body: {
    ...defaultPageStyles,
    "& .cardBody": {

    }
  }
})

export default aboutPageStyles
